import * as React from 'react';
import Routes from 'routes';
import 'translations';
import { ScrollToTop } from 'components';
import { ConfigProvider, App as AntApp } from 'antd';
import appTheme from 'theme/theme';
import { AuthContextProvider } from 'contexts/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';

export default function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retryDelay: 30 * 1000,
        retry: process.env.NODE_ENV !== 'development',
      },
      mutations: {
        retry: false,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider {...appTheme}>
        <AntApp>
          <AuthContextProvider>
            <ScrollToTop />
            <Routes />
          </AuthContextProvider>
        </AntApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}
